import React from 'react'
import './CMS.css'
import Globe from '../../assets/second_sm_img2.png'
import Rocket from '../../assets/rocket_img.png'
import Planet from '../../assets/second_sm_img1.png'
import CloudsBg from './CloudsBg'

const CMS = () => {
  return (
    <div className="cms">
        <div className="bg-clouds">
            <CloudsBg/>
        </div>
        <div className="cms-content">
            <div className="first-cms-content">
                <h3>sohoby a cms system provided by cloud systems</h3>
                <h1>Developers of mobile apps,websites,</h1>
                <h1>landing pages and cloud systems!</h1>
                <h1>Much more than a cloud...</h1>
                <div className="read">
                    <a href="/">Read more</a>
                    <img src={Globe} alt="" />
                </div>
            </div>
            <div className="planet">
            <img src={Planet} alt="" />
            </div>
            <div className="rocket">
                
                <img src={Rocket} alt="" />
            </div>
        </div>
    </div>
  )
}

export default CMS